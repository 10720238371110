import * as React from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Avatar, Box, CircularProgress, IconButton, ListItem } from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

import TouchRipple, {
  TouchRippleActions,
  TouchRippleProps,
} from "@mui/material/ButtonBase/TouchRipple";
import { downloadFile } from "./fileListFunction";

const useRefDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = React.useState({ width: 1, height: 2 });
  React.useEffect(() => {
    if (ref && ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    }
  }, [ref]);
  return dimensions;
};

export interface Idir {
  parent: string;
  hidrive_id: string;
  directLink: string;
  children: Idir[];
  type: string;
  isPathHausverwaltung?: boolean;
}

export interface Idir_Users_Userclasses extends Idir {
  sharedUsers?: string[];
  sharedUsergroups?: string[];
}

interface IFolderNameTextProps {
  dir: Idir;
  fileActions?: Function;
  downloadFunction?: Function;
}

function FolderNameText(props: IFolderNameTextProps) {
  const { dir, fileActions, downloadFunction } = props;
  const [text, setText] = React.useState(dir.parent);
  const [text_directLink, setText_directLink] = React.useState(dir.directLink);
  
  const box = React.useRef<HTMLDivElement>(null);
  const dimensions = useRefDimensions(box);
  return (
    <Box
      sx={{
        flex: 1,
        maxWidth: "calc(100% - 96px)",
        overflow: "hidden",
        overflowWrap: "anywhere",
      }}
      ref={box}
    >
      
      {text !== "" && !text_directLink && (
        <ListItemText
          className=".snake-border"
          onClick={downloadFunction ? () => downloadFunction(dir.hidrive_id, dir.parent) : ()=>{}}
          primary={text.replaceAll(">_>", "/")}
          sx={{ maxWidth: dimensions.width }}
          />
        )}
      {text_directLink && (
        <ListItemText
        className=".snake-border"
        onClick={downloadFunction ? () => downloadFunction(dir.hidrive_id, dir.parent) : ()=>{}}
        primary={text.replaceAll(">_>", "/")}
        secondary={text_directLink.replaceAll(">_>", "/")}
          sx={{ maxWidth: dimensions.width }}
        />
      )}
    </Box>
  );
}

interface IListElementFolderProps {
  depth: number;
  dir: Idir;
  open: { [key: string]: boolean };
  clickedID: string | undefined;
  testEl: React.RefObject<HTMLLIElement>;
  handleClick: Function;
  recursiveList: Function;
  FileFolderActions?: Function;
  isPathHausverwaltung?: boolean;
}

function ListElementFolder(props: IListElementFolderProps) {
  const {
    depth,
    dir,
    open,
    clickedID,
    testEl,
    handleClick,
    recursiveList,
    FileFolderActions,
    isPathHausverwaltung=false,
 
  } = props;

  const [local_dir, set_local_dir] = React.useState(dir);
  React.useEffect(() => {
    set_local_dir(dir);
  }, [dir]);
  const rippleRef = React.useRef<TouchRippleActions>(null);
  const onRippleStart = (e: React.MouseEvent<HTMLElement>) => {
    rippleRef?.current?.start(e);
  };
  const onRippleStop = (e: React.MouseEvent<HTMLElement>) => {
    rippleRef?.current?.stop(e);
  };
  return (
    <>
      <ListItem
        onClick={(e) =>{
          
          handleClick(local_dir.parent + "_" + local_dir.hidrive_id, e)
        }
        }
        sx={{
          m: 0,
          //ml: 0,//depth==0?2* depth:0 ,
          width: "100%", //depth==0?'calc(100% - '+String(2 * depth*8)+ 'px)':'100%',
          //maxWidth: '235px',//'calc(100% - '+String(2 * depth*8)+ 'px)',
          flex: 1,
          scrollMarginTop: 85,
          backgroundColor: open[local_dir.parent + "_" + local_dir.hidrive_id]
            ? "primary.folder_open" /*"#357bc138"*/
            : "inherit", // "inherit",
          display: "flex",
          flexDirection: "row",
        }}
        onMouseDown={onRippleStart}
        onMouseUp={onRippleStop}
        key={local_dir.parent + "_" + local_dir.hidrive_id}
        ref={
          local_dir.parent + "_" + local_dir.hidrive_id === clickedID
            ? testEl
            : null
        }
      >
        {/* <ListItemButton onClick={()=>handleClick(haus.name)}> */}
        <TouchRipple ref={rippleRef} center={false} />
        <ListItemIcon sx={{}}>
          {!open[local_dir.parent + "_" + local_dir.hidrive_id] && (
            <FolderIcon></FolderIcon>
          )}
          {open[local_dir.parent + "_" + local_dir.hidrive_id] && (
            <FolderOpenIcon></FolderOpenIcon>
          )}
        </ListItemIcon>
        <FolderNameText dir={local_dir}></FolderNameText>
        {/* <IconButton color="primary" edge="end" aria-label="download" onClick={(e) => handleMobileMenuOpen(e, dir)}>
                                <MoreVertIcon />
                            </IconButton> */}
        {/* {FileFolderActions && FileFolderActions(dir)()} */}
        {FileFolderActions && FileFolderActions(dir, isPathHausverwaltung||dir.parent.includes("Hausverwaltung"))}
        <IconButton
        
          color="secondary"
          edge="end"
          aria-label="download"
          key={dir.parent + "_" + dir.hidrive_id + "_button"}
          onClick={(e) =>
            handleClick(local_dir.parent + "_" + local_dir.hidrive_id, e)
          }
        >
          
          {open[local_dir.parent + "_" + local_dir.hidrive_id] ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
                  </IconButton>
        <IconButton
          sx={{visibility: "hidden"}}
        ></IconButton>
        {/* </ListItemButton> */}
      </ListItem>
      <ListElementFolderSubElements
        key={dir.parent + "_" + dir.hidrive_id + "_subFolderELements"}
        depth={depth}
        open={open}
        dir={local_dir}
        recursiveList={recursiveList}
        FileFolderActions={FileFolderActions}
        isPathHausverwaltung={isPathHausverwaltung||dir.parent.includes("Hausverwaltung")}
      ></ListElementFolderSubElements>
    </>
  );
}

interface IListElementFolderSubElementsProps {
  depth: number;
  open: { [key: string]: boolean };
  dir: Idir;
  recursiveList: Function;
  isPathHausverwaltung: boolean;
  FileFolderActions: Function | undefined;
}

function ListElementFolderSubElements(
  props: IListElementFolderSubElementsProps
) {
  const { depth, open, dir, recursiveList, FileFolderActions, isPathHausverwaltung=false } = props;
  return (
    <>
      {/* <Box sx={{ width: '100%', height: '50px', backgroundColor: 'green' }}
            ></Box> */}
      <Collapse
        sx={{
          width: "100%", //'calc(100% - '+String(2 * (depth+1)*8)+ 'px)',
          maxWidth: "100%", //'calc(100% - '+String(2 * (depth+1)*8)+ 'px)',
          //ml:2*depth,
          display: "flex",
          flexDirection: "row",
          pl: 1,
        }}
        in={open[dir.parent + "_" + dir.hidrive_id]}
        timeout={"auto"}
        unmountOnExit
        key={dir.parent + "_" + dir.hidrive_id + "_collapse"}
      >
        {/* <Box sx={{
                    width: '100%',//'calc(100% - ' + String(2 * (1) * 8) + 'px)',
                        maxWidth: '100%',//'calc(100% - ' + String(2 * (1) * 8 ) + 'px)',
                        //ml: 2 * depth,
                        // width: 'calc(100% - '+String(2 * depth*8)+ 'px)',
                        overflow: 'hidden',
                        bgcolor: 'yellow',height:50}}></Box> */}
        <List
          //component="nav" //disablePadding
          key={dir.parent + "_" + dir.hidrive_id + "_Listcollapse"}
          sx={{
            maxWidth: "100%",
            width: "100%",
            // width: 'calc(100% - ' + String(2 * (depth-1) * 8) + 'px)',
            // maxWidth: 'calc(100% - ' + String(2 * (depth-1) * 8 ) + 'px)',
            //ml: 2 * depth,
            // width: 'calc(100% - '+String(2 * depth*8)+ 'px)',
            overflow: "hidden",
            bgcolor: "background.paper",
          }}
        >
          {open[dir.parent + "_" + dir.hidrive_id] &&
            recursiveList(dir.children, depth + 1, FileFolderActions, isPathHausverwaltung)}
        </List>
      </Collapse>
    </>
  );
}

interface IListElementFileProps {
  dir: Idir;
  downloading: { [key: string]: boolean };
  downloadFile: Function;
  fileFolderActions?: React.JSX.Element;
}

function ListElementFile(props: IListElementFileProps) {
  const { dir, downloading, fileFolderActions } = props;
  const [downloadingBoarderAnimation,setDownloadingBoarderAnimation] = React.useState(false)
  const callback_setDownloading = React.useCallback((test: boolean) => {
    setDownloadingBoarderAnimation(test);
  }, []);
  return (
    <ListItem className={downloadingBoarderAnimation?".snake-border":""} onClick={()=>{downloadFile(()=>{})(dir.hidrive_id, dir.parent)}}
      key={dir.parent + "_" + dir.hidrive_id}
      sx={{
        //ml: 2 * depth,
        // width: 'calc(100% - ' + String(2 * depth * 8) + 'px)',
        //maxWidth:'calc(100% - '+String(2 * depth*8)+ 'px)',
        // display: 'flex',
        // flexDirection: 'row',
        // flex: 1,
        ":hover":{backgroundColor:"lightgray"},
        cursor:"pointer",
        m: 0,
        ml: 0, //depth==0?2* depth:0 ,
        width: "100%", //depth==0?'calc(100% - '+String(2 * depth*8)+ 'px)':'100%',
        //maxWidth: '235px',//'calc(100% - '+String(2 * depth*8)+ 'px)',
        flex: 1,
        // scrollMarginTop: 85,
        // backgroundColor: open[dir.parent + "_" + dir.hidrive_id] ? "#357bc138" : "red",// "inherit",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ListItemIcon>
        <InsertDriveFileIcon color="primary"></InsertDriveFileIcon>
      </ListItemIcon>

      <FolderNameText dir={dir} downloadFunction={downloadFile(callback_setDownloading)}></FolderNameText>
      {/* <ListItemText primary={dir.parent} sx={{ flex: 1, maxWidth: 'calc(100% - 150px)', overflow: 'hidden' }} /> */}
      {fileFolderActions !== undefined && fileFolderActions}
      {/* {downloading[dir.hidrive_id] && (
        <CircularProgress
          size={50}
          sx={{
            color: "secondary",
            position: "relative",
            top: "1px",
            left: "44px",
            //zIndex: -1,
          }}
        />
      )}
      <IconButton
        color="primary"
        aria-label="download"
        onClick={() => downloadFile(dir.hidrive_id, dir.parent)}
      >
        {<DownloadIcon />}
      </IconButton> */}
    </ListItem>
  );
}

interface IRecursiveFileListeProps {
  open: { [key: string]: boolean };
  clickedID: string | undefined;
  testEl: React.RefObject<HTMLLIElement>;
  handleClick: Function;
  downloadFile: Function;
  downloading: { [key: string]: boolean };
  tempDocumentList: Idir;
  fileActions?: Function;
  isPathHausverwaltung?: boolean;
}

export default function RecursiveFileListe(props: IRecursiveFileListeProps) {
  const {
    open,
    clickedID,
    testEl,
    handleClick,
    downloadFile,
    downloading,
    tempDocumentList,
    fileActions,
    isPathHausverwaltung = false,
  } = props;

  const [local_tempDocumentList, set_Local_TempDocumentList] =
    React.useState(tempDocumentList);
  React.useEffect(() => {
    set_Local_TempDocumentList(tempDocumentList);
    
  }, [tempDocumentList]);
  const recursiveList = (
    childrenOfParent: Idir[],
    depth: number,
    fileAction: Function | undefined,
    isPathHausverwaltung: boolean = false
  ) => {
    childrenOfParent.sort((a, b) =>
      a.type === b.type
        ? a.parent > b.parent
          ? 1
          : a.parent < b.parent
          ? -1
          : 0
        : a.type > b.type
        ? 1
        : a.type < b.type
        ? -1
        : 0
    );
    return childrenOfParent.map((dir) => {
     
      if (dir.type === "dir") {
        return (
          <>
            {true && (
              <>
                <ListElementFolder
                  key={dir.hidrive_id + String(Math.floor(Math.random() * 10))}
                  depth={depth}
                  dir={dir}
                  open={open}
                  clickedID={clickedID}
                  testEl={testEl}
                  handleClick={handleClick}
                  recursiveList={recursiveList}
                  FileFolderActions={fileAction ? fileAction : undefined}
                  isPathHausverwaltung={isPathHausverwaltung}
                ></ListElementFolder>
              </>
            )}
            {/* <ListElementFolderSubElements
                            depth={depth}
                            open={open}
                            dir={dir}
                            recursiveList={recursiveList}
                        ></ListElementFolderSubElements> */}
            {/* <Divider sx={{ ml: 4 * depth / 2 }} /> */}
          </>
        );
      } else {
        return (
          // <></>
          <ListElementFile
            key={dir.hidrive_id + String(Math.floor(Math.random() * 10))}
            dir={dir}
            downloading={downloading}
            downloadFile={downloadFile}
            // fileFolderActions={fileActions ? fileActions(dir)() : undefined}
            fileFolderActions={fileActions ? fileActions(dir) : undefined}
          ></ListElementFile>
        );
      }
    });
  };

  return <>{recursiveList(local_tempDocumentList.children, 0, fileActions,isPathHausverwaltung)}</>;
}
