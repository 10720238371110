import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IndicatorWorktime from "./IndicatorWorktime";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import { ArbeitszeitService, OpenAPI } from "../../../../../services/openapi";
import {
  addDaysToDate,
  convertDateToISODateString,
  getIsoWeekOfDate,
} from "../timehelperFunctions";
import { IWorktime } from "./interfaces";
import dayjs from "dayjs";
import LinkGeneratorForNFC from "./LinkGeneratorForNFC";
import { DatePicker } from "@mui/x-date-pickers";
import ZeiterfassungDashboardContext from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContext";

export default function FilterUser() {
  const {
    arbeitszeiten_alle_MA,
    setFilter,
    handle_Upadate_Arbeitszeiten_alle_MA,
    filter,
    applyFilter,
  } = useContext(ZeiterfassungDashboardContext);
  const [selectedUser, setSelectedUser] = useState<string>();
  const [filtered_arbeitszeiten_alle_MA, setFiltered_arbeitszeiten_alle_MA] =
    useState<any>(applyFilter(arbeitszeiten_alle_MA, true));

  useEffect(() => {
    let filtered = applyFilter(arbeitszeiten_alle_MA, true)
   
    setFiltered_arbeitszeiten_alle_MA(filtered);
  }, [applyFilter, arbeitszeiten_alle_MA,filter]);

  useEffect(() => {
    setFilter({ ...filter, user: selectedUser });
  }, [selectedUser, setFilter]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedUser(event.target.value);
  };

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
        <InputLabel id="demo-simple-select-standard-label">
          Mitarbeiter/-in
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selectedUser}
          onChange={handleChange}
          label="Mitarbeiter/-in"
        >
          <MenuItem value="">
            <em>Alle</em>
          </MenuItem>
          {filtered_arbeitszeiten_alle_MA &&
            "user_zeiterfassung" in filtered_arbeitszeiten_alle_MA &&
            filtered_arbeitszeiten_alle_MA["user_zeiterfassung"].map(
              (user: any) => (
                <MenuItem value={user.user_id}>
                  {user.fullname && user.fullname !== ""
                    ? user.fullname
                    : user.email}
                </MenuItem>
              )
            )}
        </Select>
      </FormControl>
    </>
    // </ThemeProvider>
  );
}
