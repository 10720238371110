import React, { useEffect } from "react";

import { Button, Icon, IconButton } from "@mui/material";
import { CloudDownload, CloudUpload, Download } from "@mui/icons-material";
import ContextElementFunctions from "../../Contexts/ContextElementFunctions";
import { downloadFile, downloadFolder } from "./background_tasks";
import FileUploader from "../../../Application/MainPages/SubPages/Dokumentenverwaltung/FileUploader";
import ContextViewFileList from "../../Contexts/ContextViewFileList";


export const UploadFile = () => {
  const { dir_id, type, name,  } = React.useContext(ContextElementFunctions);
  const { update_dir_view,  } = React.useContext(ContextViewFileList);
      const [openFileUploader, setOpenFileUploader] = React.useState(false);
  
  const upload = () => {
    setOpenFileUploader(true);

  }
  const handleCloseFileUploader = () => {
//     update_dir_view(dir_id);
      setOpenFileUploader(false);
    
  };

  return (
    <>
    { (type === "dir") &&
<>
      <IconButton onClick={(e) => {e.stopPropagation();   upload();}} title={"Datei hochladen"}>
            <CloudUpload />
            </IconButton>
            <FileUploader
                onClose={handleCloseFileUploader}
                open={openFileUploader}
                title ={"Dateien hochladen in "+name}
                dir_id={dir_id}
            ></FileUploader> 
  </>      
        }
        </>
     
  );
};
