import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { BauvorhabenWithID } from '../../services/openapi';

export interface IArbeitszeitenDay {
    start:Date,
    ende:Date,
    pause:number,
    bauvorhaben:string
    taetigkeit:string|undefined
    id:number
  
  }

interface ZeiterfassungDashboardContextInterface {
    selectedDateStart:Date,
                setSelectedDateStart:(date:Date)=>void,
                selectedDateEnde:Date,
                setSelectedDateEnde:(date:Date)=>void,
                arbeitszeiten_alle_MA:any,
                setArbeitszeiten_alle_MA:(arbeitszeiten_alle_MA:any)=>void,
                handle_Upadate_Arbeitszeiten_alle_MA:()=>void,
                setFilter:(new_filter:any)=>void,
                filter:any,
                applyFilter:(arbeitszeit_dict:any,withoutUser?:boolean)=>any
    
    
}


const defaultContext: ZeiterfassungDashboardContextInterface = {
    selectedDateStart: new Date(new Date().setHours(0,0,0,0)),
                setSelectedDateStart: () => {},
                selectedDateEnde: new Date(new Date().setHours(0,0,0,0)),
                setSelectedDateEnde: () => {},
                arbeitszeiten_alle_MA: {},
                setArbeitszeiten_alle_MA: () => {},
                handle_Upadate_Arbeitszeiten_alle_MA: () => {},
                filter:{},
                setFilter:()=>{},
                applyFilter:()=>{}
    
    
};

const ZeiterfassungDashboardContext = React.createContext<ZeiterfassungDashboardContextInterface>(defaultContext);

export default ZeiterfassungDashboardContext;
