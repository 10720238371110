import * as React from "react";




import APIWrapperContext from "../../../../services/APIWrapperContext";
import { Idir_Users_Userclasses } from "../../../../List/FileList_Components";
import { IuserElement, IuserSelectable, TonCloseEditSharedUsers } from "../../../../Application/MainPages/Interfaces/interfaces";
import { DocumentsService, UserService } from "../../../../services/openapi";
import DialogRawEditSharedUsers from "../../../../Application/MainPages/SubPages/Dokumentenverwaltung/DialogWindowEditSharedUsers";
import ContextViewFileList from "../../../Contexts/ContextViewFileList";

interface IEditSharedUsersProps {
  openDialog_EditSharedUsers: boolean;
  // selectedDocument: Idir_Users_Userclasses | undefined;
  // handleClose_EditSharedUsers: TonCloseEditSharedUsers;
  handleClose: Function;
  dir_id: string;
}

export default function EditSharedUsers({
  openDialog_EditSharedUsers,
  // selectedDocument,
  
  dir_id,
  handleClose
}: IEditSharedUsersProps) {
  const { call_backend_api } = React.useContext(APIWrapperContext);
  const { accessible_elements, curr_dir_view, update_accessible_elements } =
    React.useContext(ContextViewFileList);

  const [users, setUsers] = React.useState<IuserElement[]>([]);

  

  React.useEffect(() => {
    if(openDialog_EditSharedUsers){

      call_backend_api(
        UserService.listUsersUserListGet(),
        (t: IuserElement[]) => setUsers(t),
        ()=>console.log("error - load Users"),
        undefined,
        false
      );
      
    }
  }, [openDialog_EditSharedUsers]);

  const handleClose_EditSharedUsers = (value?:IuserSelectable[] | undefined) => {
    if(value){
     
      // Update the shared users
      
          call_backend_api(
                  DocumentsService.updateDocumentByIdDocUpdatePost(
                    dir_id,
                    value.map((v) => v.email)
                  ),
                  ()=>{update_accessible_elements();},
                  undefined,
                  undefined,
                  true,
                  "Freigegebene Nutzer aktualisiert",
                  "Fehler beim Aktualisieren der freigegebenen Nutzer"
                );
      // Update the visualization
    }
    handleClose();
  }

  return (
    <>
        
        <DialogRawEditSharedUsers
          open={openDialog_EditSharedUsers}
          onClose={handleClose_EditSharedUsers}
          text={""}
          title={"Wohnung / Zimmer bearbeiten"}
          users={users.map((u) => {
            return { name: u.fullname, email: u.email, checked: false };
          })}
          owners={
            accessible_elements && accessible_elements[dir_id] && accessible_elements[dir_id].users && accessible_elements[dir_id].users!==undefined
              ?  (accessible_elements[dir_id].users as string[]).map((u: string) => {
                  return { name: u, email: u, checked: false };
                })
              : []
          }
          confirmText={"Übernehmen"}
        ></DialogRawEditSharedUsers>
      
    </>
  );
}
