import React from 'react';
import { DirView } from '../../services/openapi/models/DirView';
import { AccessibleDocument } from '../../services/openapi';

export interface I_dir_id_history {
    id: string;
    name: string;

}

interface ContextViewFileListInterface {
    dir_id_history:I_dir_id_history[];
    curr_dir_id:string|undefined;
    curr_dir_view: DirView|{};
    accessible_elements: Record<string, AccessibleDocument>;
    user_specific: boolean|undefined;
    loading_dir: boolean;
    update_accessible_elements: () => void;
    setCurrDirId: (dir_id: string) => void;
    setCurrDirView: (dir_view: DirView) => void;
    // setDirIdHistory: (dir_id_history: string[]) => void;
    dir_navigate_back: (id?:string) => void;
    dir_navigate_forward: (id:string) => void;
    init_dir_view: () => void;
    update_dir_view: (id:string|undefined) => void;
    
}


const defaultContext: ContextViewFileListInterface = {
    dir_id_history:[],
    curr_dir_id:"",
    curr_dir_view: {},
    accessible_elements: {},
    user_specific: true,
    loading_dir: false,
    update_accessible_elements: ()=>{},
    setCurrDirId: ()=>{

    },
    setCurrDirView: ()=>{

    },

    dir_navigate_back: ()=>{

    },
    dir_navigate_forward: ()=>{

    },
    init_dir_view: ()=>{

    },
    update_dir_view: ()=>{
        
    }

    
    
};

const ContextViewFileList = React.createContext<ContextViewFileListInterface>(defaultContext);

export default ContextViewFileList;
