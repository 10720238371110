import React, { useEffect } from "react";

import { Button, Icon, IconButton } from "@mui/material";
import { CloudDownload, CloudUpload, Download, Search } from "@mui/icons-material";
import ContextElementFunctions from "../../Contexts/ContextElementFunctions";
import { downloadFile, downloadFolder } from "./background_tasks";
import { DialogShowPDF } from "./DialogShowPDF";


export const ShowPDF = () => {
  const { dir_id, type, name } = React.useContext(ContextElementFunctions);

  const [openPDF, setOpenPDF] = React.useState(false);
  
  const showPDF = () => {
    setOpenPDF(true);

  }

  return (
    <>
      { name.includes(".pdf") &&
<>

        <IconButton onClick={(e) => {e.stopPropagation();   showPDF();}} title={"PDF anzeigen"}>
            <Search />
            </IconButton>
        <DialogShowPDF onClose={()=>{setOpenPDF(false)}} open={openPDF} url={dir_id} />
  </>
        }
        </>
     
  );
};
