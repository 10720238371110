import React, { ReactNode, useContext } from 'react';


import ZeiterfassungContext, { IArbeitszeitenDay } from './ZeiterfassungContext';
import { useNavigate } from 'react-router-dom';
import Session, { SessionAuth, SessionContext, doesSessionExist, useSessionContext } from "supertokens-auth-react/recipe/session";
import { call_backend_api } from '../../services/APIWrapper';
import { ApiError, ArbeitszeitService, Arbeitszeiteintrag, Bauvorhaben, BauvorhabenWithID, UserService, newUser_return } from '../../services/openapi';
import dayjs, { Dayjs } from 'dayjs';
import APIWrapperContext from '../../services/APIWrapperContext';

interface IZeiterfassungContextProviderProps {
    children?: ReactNode;

}

interface Arbeitszeiteintrag_bvhID extends Omit<Arbeitszeiteintrag, 'bvh'>  {
  bvh:number
}

const ZeiterfassungContextProvider = ({ children }: IZeiterfassungContextProviderProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const {call_backend_api} = useContext(APIWrapperContext)
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date(new Date().setHours(0,0,0,0)));
    const [arbeitszeitenDay,setArbeitszeitenDay] = React.useState<IArbeitszeitenDay[]>([])
    const [bauvorhaben, setBauvorhaben] = React.useState('');
    const [bauvorhabenList, setBauvorhabenList] = React.useState<BauvorhabenWithID[]>([]);
    const [createNeueZeitmeldung, setCreateNeueZeitmeldung] = React.useState<boolean>(false);
    const [startZeit, setStartZeit] = React.useState<Dayjs | null>(null);
    const [endeZeit, setEndeZeit] = React.useState<Dayjs | null>(null);
    const [pauseZeit, setPauseZeit] = React.useState<Dayjs | null>(
      dayjs("1900-01-01T01:00")
    );
    const [taetigkeit,setTaetigkeit]= React.useState<string>("");
    const resetForm =()=>{
      setBauvorhaben('');
      setStartZeit(null);
      setEndeZeit(null);
      setPauseZeit(dayjs("1900-01-01T01:00"));
    }

    const parseDateFromString = (dateString:string)=>{
      let tmpDate = new Date(Date.parse(dateString))
      tmpDate.setSeconds(0)
      tmpDate.setMilliseconds(0)
      return tmpDate
    }

    const deleteArbeitszeit = (id:number) => {
        
        call_backend_api(ArbeitszeitService.deleteWorktimesOfDateArbeitzeitArbeitszeiteintragDelete(id),()=>{setArbeitszeitenDay(arbeitszeitenDay.filter((arbeitszeit)=>arbeitszeit.id!==id))},undefined,undefined,true)
    }

    const updateBauvorhabenList = () => {
      call_backend_api(ArbeitszeitService.getAlleBauvorhabenArbeitzeitBauvorhabenGet(), (response:any) => {
        setBauvorhabenList(response);
      },undefined,undefined,true)
    }

    React.useEffect(() => {
      updateBauvorhabenList()

        
      }, []);

    const updateArbeitzeitenListe = (selectedDate:Date) => {
      let year = selectedDate.toLocaleString("default", { year: "numeric" });
      let month = selectedDate.toLocaleString("default", { month: "2-digit" });
      let day = selectedDate.toLocaleString("default", { day: "2-digit" });
      


      call_backend_api(ArbeitszeitService.getWorktimesOfDateArbeitzeitArbeitszeiteintragDateGet(year + "-" + month + "-" + day), (response:any) => {
        


        setArbeitszeitenDay(response.map((el:any)=>{return {
          id:el.id,
          start:parseDateFromString(el.beginn),
          ende:parseDateFromString(el.ende),
          pause:el.pause,
          bauvorhaben:el.bvh.bvh_name,
          taetigkeit:el.taetigkeit
        }}).sort((a:IArbeitszeitenDay,b:IArbeitszeitenDay)=>a.start.getTime()-b.start.getTime()));

      }, (error: ApiError) => {
        console.log(error);
      },undefined,false
    )
    }

    // const { token,setAuthed,setCheckingAuthed } = React.useContext(SecurityContext)
    React.useEffect(() => {
      // Get year, month, and day part from the date
      setArbeitszeitenDay([])
      updateArbeitzeitenListe(selectedDate)
      


      
      
      },[selectedDate])
    
      const sendArbeitszeit = () => {
        let selectedBauvorhaben = bauvorhabenList.find((el)=>el.bvh_name===bauvorhaben)
        
        const transformTime = (time:Dayjs | null, day:Date) => {
          if(time!==null){

            time = time.clone().set('year',day.getFullYear()).set('month',day.getMonth()).set('date',day.getDate())
            return time.toISOString()
            //return time.format("YYYY-MM-DDTHH:mm:ss:SSSZ")
          }else{
            return ""
          }
        }

        if(selectedBauvorhaben!==undefined && startZeit!==null && endeZeit!==null && pauseZeit!==null){
        


        let arbeitszeiteintrag:Arbeitszeiteintrag = {
          bvh: selectedBauvorhaben,
          datum: selectedDate.getFullYear()+"-"+(selectedDate.getMonth()+1).toString().padStart(2,'0')+"-"+selectedDate.getDate().toString().padStart(2,'0'),
          beginn: startZeit?transformTime(startZeit,selectedDate):"", // startZeit?.format("HH:mm") || "",
          ende: endeZeit?transformTime(endeZeit,selectedDate):"", //endeZeit?.format("HH:mm") || "",
          pause: pauseZeit?pauseZeit.diff(dayjs("1900-01-01T00:00"))/1000:0,
          dauer:  endeZeit?.diff(startZeit)/1000-pauseZeit?.diff(dayjs("1900-01-01T00:00"))/1000 || 0,
          bearbeiter: "me",
          kurzinfo: "info",
          notiz: "note",
          taetigkeit: taetigkeit,
        }
     
        
        call_backend_api(ArbeitszeitService.addWorktimeArbeitzeitArbeitszeiteintragPost(arbeitszeiteintrag),undefined,undefined,()=>{updateArbeitzeitenListe(selectedDate)},true)
      }
      }
      const createNewBauvorhaben = (mandnr:number,projektnr:number,bvh_name:string) => {
        call_backend_api(
          ArbeitszeitService.createBauvorhabenArbeitzeitBauvorhabenPost({
            mandnr: mandnr,
            projektnr: projektnr,
            bvh_name: bvh_name,
          }),
          () => {
            updateBauvorhabenList();
            setBauvorhaben(bvh_name);
          },
          () => {},
          undefined,
          true
        );
      };

    return (
        
        <ZeiterfassungContext.Provider
            value= {{
        selectedDate,setSelectedDate,
        arbeitszeitenDay,setArbeitszeitenDay,deleteArbeitszeit,bauvorhaben,setBauvorhaben,bauvorhabenList,createNeueZeitmeldung,setCreateNeueZeitmeldung,
        startZeit,setStartZeit,endeZeit,setEndeZeit,pauseZeit,setPauseZeit,resetForm,taetigkeit,setTaetigkeit,sendArbeitszeit,createNewBauvorhaben

    }
}
        >
    { children }
    </ZeiterfassungContext.Provider>
    );
};

export default ZeiterfassungContextProvider;
