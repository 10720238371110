import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Chip, Paper } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface IUserManageGroupsDialogsProps{
    children?: React.ReactNode,
    title:string,
    groupList:string[],
    usersGroups:string[],
    open:boolean,
    closeFunction:(newValue:string[]|undefined,action:string|undefined)=>void,
}

export default function UserManageGroupsDialogs({children, title, groupList=[], usersGroups=[],open=false, closeFunction=(newValue,action)=>{} }:IUserManageGroupsDialogsProps) {
    const [groupList_state,setGrouplist_state] = React.useState(groupList)
    const [Usergroups_state,setUsergroups_state] = React.useState(usersGroups)
    const radioGroupRef = React.useRef<HTMLElement>(null);

    const handleSave= () => {
        closeFunction(Usergroups_state,"changeUserGroup")
    }
    const handleAbort= () => {
        closeFunction(undefined,undefined)
    }
    React.useEffect(()=>{
       
    },[groupList_state])

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };
    React.useEffect(()=>{
        setUsergroups_state(usersGroups)
        setGrouplist_state(groupList)
    },[groupList, usersGroups])

    const addUsergroup = (groupName:string)=>{
        let groupList_tmp = [...groupList_state]
        groupList_tmp=groupList_tmp.filter(g=>g!==groupName)
        let usersGroups_tmp = [...Usergroups_state]
        usersGroups_tmp.push(groupName)
        setGrouplist_state(groupList_tmp)
        setUsergroups_state(usersGroups_tmp)
    }
    const removeUsergroup = (groupName:string)=>{
        let usersGroups_tmp = [...Usergroups_state]
        usersGroups_tmp=usersGroups_tmp.filter(g=>g!==groupName)
        let groupList_tmp = [...groupList_state]
        groupList_tmp.push(groupName)
        setUsergroups_state(usersGroups_tmp)
        setGrouplist_state(groupList_tmp)
    }



    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}

        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
            <h4>Nutzergruppen des Users</h4>
            <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
                {Usergroups_state.map(g => {
                    
                    return (
                        <ListItem 
                        // key={g}
                        >

                            <Chip

                                label={g}
                                onDelete={()=>{ removeUsergroup(g)}}
                            />
                        </ListItem>
                    )
                })
                }
</Paper>
<h4>Verfügbare Nutzergruppen</h4>
<Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
{groupList_state.map(g => {
                  
                    return (
                        <ListItem 
                        key={g}
                        >

                            <Chip

                                label={g}
                                deleteIcon={<AddCircleOutlineIcon />}
                                onDelete={()=>{
                                    addUsergroup(g)
                                }}
                            />
                        </ListItem>
                    )
                })
                }
</Paper>



            </DialogContent>
            <DialogActions>

                <Button autoFocus onClick={handleSave}>Speichern</Button>
                <Button  onClick={handleAbort}>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
}



