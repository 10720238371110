import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Avatar, PopoverVirtualElement } from "@mui/material";
import AppBarContext from "../../Contexts/AppBarContext/AppBarContext";
import Session from "supertokens-auth-react/recipe/session";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import Badge from "@mui/material/Badge";
import APIWrapperContext from "../../services/APIWrapperContext";
import { UserService } from "../../services/openapi";
import { DocumentScanner, Task } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import UserContext from "../../Contexts/UserContext/UserContext";
const drawerWidth = 340;
const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => true,
})(({ theme }) => ({
  width: "100%", //`calc(100% - ${drawerWidth}px)`,
  marginLeft: 0, //`${drawerWidth}px`,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

interface IAppBarProps {}

export default function AppBar(props: IAppBarProps) {
  //   const theme = useTheme();
  const {additionalUserdata,reset_NewDocumentNotifications} = React.useContext(UserContext)
  const { openDrawer, setOpenDrawer } = React.useContext(AppBarContext);
  const {call_backend_api} = React.useContext(APIWrapperContext)
  // const { token, sessionStart, setToken, setSessionStart, authed } = React.useContext(SecurityContext)
  const [open, setOpen] = React.useState(false);

  let sessionContext = Session.useSessionContext();

  const [notification_anchorEl, setNotification_AnchorEl] =
    React.useState<null | HTMLElement>(null);
  const notification_open = Boolean(notification_anchorEl);
  const notification_handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotification_AnchorEl(event.currentTarget);
  };
  const notification_handleClose = (jumpToStart?:boolean|null) => {
    reset_NewDocumentNotifications();
    if(jumpToStart) navigate("/")
    setNotification_AnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const navigate = useNavigate()

  const [notifications,setNotifications] = React.useState<number>(0)

  const updateNotifications =()=>{
    if(!sessionContext.loading && sessionContext.doesSessionExist && additionalUserdata){
      setNotifications(additionalUserdata.notifications?additionalUserdata.notifications:0)
      
    }
  }

  React.useEffect(()=>{
    updateNotifications();
  },[sessionContext.loading , (sessionContext as any).doesSessionExist,additionalUserdata])

  const [anchorEl, setAnchorEl] = React.useState<
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined
  >(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined
  >(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  async function handleMenuCloseAndLogout() {
    setAnchorEl(null);
    await signOut();
    window.location.href = "/";
    handleMobileMenuClose();
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem
        onClick={() => {
          navigate("/user_options");
          handleMenuClose();
        }}
      >
        Mein Account
      </MenuItem>
      <MenuItem onClick={handleMenuCloseAndLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <AppBarStyled position="fixed" color={"backgroundboe" as any}>
        <Toolbar>
          {!sessionContext.loading && sessionContext.doesSessionExist && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <IconButton
            sx={{ p: 0 }}
            href="https://www.boehringer.net/"
            target="_blank"
          >
            <Avatar
              alt="Logo"
              src="/logoboe.svg"
              variant="square"
              sx={{ height: "70px", width: "70px", marginRight: "24px" }}
            />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {/* Böhringerk */}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "flex", md: "flex" } }}>
            {!sessionContext.loading && sessionContext.doesSessionExist && (
              <>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={notification_handleClick}
                  color="inherit"
                  sx={{ mr: 1 }}
                  disabled={notifications==0}
                >
                  <Badge badgeContent={notifications} color="primary" invisible={notifications==0}>
                    <NotificationsActiveIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </>
            )}
          </Box>
          <Menu
            anchorEl={notification_anchorEl}
            id="account-menu"
            open={notification_open}
            onClose={()=>{notification_handleClose()}}
            onClick={()=>{notification_handleClose()}}
             sx={{ maxWidth:'calc(100% - 60px)'}}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  
                  overflow: "visible",
                  overflowWrap: 'anywhere',
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 10,
                    width: 20,
                    height: 20,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {/* <MenuItem> */}
            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',p:1,cursor:'pointer'}} onClick={()=>{notification_handleClose(true)}}>
            <Badge badgeContent={notifications} color="primary" invisible={notifications==0}>
                    <Task />
                  </Badge>
            <Typography sx={{ pl:1.5,pb: 1, pt: 1, minWidth:100 }} variant="subtitle2">
              In Ihrer Dateiübersicht befinden sich {notifications} {notifications===1?"neues":"neue"} {notifications===1?"Dokument":"Dokumente"}.
              </Typography>
              </Box>
            {/* </MenuItem> */}
          </Menu>
          {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {token &&
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            }
          </Box> */}
        </Toolbar>
      </AppBarStyled>
      {renderMobileMenu}
      {renderMenu}
    </>
    // </ThemeProvider>
  );
}
