import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Checkbox,
  Grid,
  ListItemIcon,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import PickingList from "./PickingList";

interface IpropsDialogRawEditAppartment {
  onClose: (value?: any) => void;
  value: any;
  open: boolean;
  title: string;
  text: string;
  hausname: string;
  addresse: string;
  haus_id: number|undefined;
  confirmText: string;
  houses: string[];
  
}

interface OutputValue{
  name: string,
        house: string,
        id: number|undefined
}

export default function DialogRawEditAppartment(
  props: IpropsDialogRawEditAppartment
) {
  const {
    onClose,
    value: valueProp,
    open,
    title,
    text,
    hausname,
    addresse,
    haus_id,
    confirmText,
    houses,
   
    ...other
  } = props;
  const [value, setValue] = React.useState<OutputValue|undefined>(undefined);
  const [value_Hausname, setValue_Hausname] = React.useState(
    hausname ? hausname : ""
  );
  const [value_Addresse, setValue_Addresse] = React.useState(
    addresse ? addresse : ""
  );
  
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      (radioGroupRef.current as any).focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    
    onClose(value);
  };

  
  const handleChangeTextfields = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>  ) => {
   
    if (event.target.id == "name" && event.target.value) {
      setValue_Hausname(event.target.value);
    }
    if (event.target.name === undefined && event.target.value) {
      setValue_Addresse(event.target.value);
    }
  };

  React.useEffect(() => {
   
    if (value_Hausname && value_Addresse ) {
    // if (value_Hausname && value_Addresse && ownersList && rentersList) {
      
      setValue({
        name: value_Hausname,
        house: value_Addresse,
        id: haus_id,
        // owners: ownersList,
        // renters: rentersList,
      });
    } else {
     
      setValue(undefined);
    }
  }, [value_Addresse, value_Hausname]);
  

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: "xl" } }}
      maxWidth="sm"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Wohnungs- / Zimmername"
          defaultValue={value_Hausname}
          fullWidth
          variant="standard"
          onChange={handleChangeTextfields}
        />
        <TextField
          id="house"
          select
          label="Haus"
          defaultValue={value_Addresse}
          fullWidth
          onChange={handleChangeTextfields}
          helperText="Bitte tragen Sie ein bereits existierendes Haus hier ein."
          variant="standard"
        >
          {houses &&
            houses.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </TextField>
        {/* <Typography>Eigentümer</Typography>
        <PickingList
          owners={true}
          users={users}
          selectedUsers={owners}
          setListFct={setOwnersList}
        ></PickingList>
        <Typography>Mieter</Typography>
        <PickingList
          owners={false}
          users={users}
          selectedUsers={renters}
          setListFct={setRentersList}
        ></PickingList> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Nein</Button>
        <Button onClick={handleOk} autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
