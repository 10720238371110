import List from "@mui/material/List";
import {
  Avatar,
  Dialog,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Person } from "@mui/icons-material";
import { useEffect, useState, useMemo } from "react";
import EditSharedUsers from "./EditSharedUsers";
import EditSharedUsergroups from "./EditSharedUsergroups";
import type { DialogProps } from "@mui/material";

interface IDialogChooseChangeUsersOrUsergroupsProps {
  onClose: Function;
  open: boolean;
  dir_id: string;
}

export function DialogChooseChangeUsersOrUsergroups({
  dir_id,
  onClose,
  open,
}: IDialogChooseChangeUsersOrUsergroupsProps) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );
  const [openDialog_EditSharedUsers, setOpenDialog_EditSharedUsers] =
    useState(false);
  const [openDialog_EditSharedUsergroups, setOpenDialog_EditSharedUsergroups] =
    useState(false);
  const options = useMemo(() => [
    "Freigegeben Nutzergruppen verwalten",
    "Freigegeben Nutzer verwalten",
  ], []);

  const handleClose = () => {
    setSelectedOption(undefined);
    setOpenDialog_EditSharedUsers(false);
    setOpenDialog_EditSharedUsergroups(false);
    
    onClose(undefined);
  };

  const handleListItemClick = (value: any) => {
    setSelectedOption(value);
  };
  useEffect(() => {
    
    if (selectedOption === options[0]) {
      setOpenDialog_EditSharedUsergroups(true);
    } else if (selectedOption === options[1]) {
      setOpenDialog_EditSharedUsers(true);
    }
    else{
      setOpenDialog_EditSharedUsergroups(false);
      setOpenDialog_EditSharedUsers(false);
    }
  }, [selectedOption, options]);
  const handleEventualOutsideClose: DialogProps["onClose"] = (event, reason) => {
    (event as MouseEvent).stopPropagation();
    if (reason && reason === "backdropClick")
        return;
    handleClose();
}
  return (
    <>
      <Dialog onClose={handleEventualOutsideClose} open={open} key={"EditShared_"+dir_id}>
        <DialogTitle>Dokumenten Zugriff Verwalten</DialogTitle>
        <List sx={{ pt: 0 }}>
          {options.map((op) => (
            <ListItem sx={{ cursor: "pointer", ":hover": { backgroundColor: "#f0f0f0" } }}
              onClick={(e) => {
                e.stopPropagation();
                handleListItemClick(op);
               
              }}
              key={op}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <Person />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={op} />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <EditSharedUsers key={"EditSharedUser_"+dir_id}
        openDialog_EditSharedUsers={openDialog_EditSharedUsers}
        handleClose={()=>{setOpenDialog_EditSharedUsers(false); handleClose()}}
        dir_id={dir_id}
      ></EditSharedUsers>
      <EditSharedUsergroups key={"EditSharedUsergroups_"+dir_id}
        openDialog_EditSharedUsergroups={openDialog_EditSharedUsergroups}
        handleClose={()=>{setOpenDialog_EditSharedUsergroups(false); handleClose()}}
        dir_id={dir_id}
      ></EditSharedUsergroups>
    </>
  );
}
