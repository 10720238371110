import React, { useEffect } from "react";
import ContextProviderViewFileList from "./Contexts/ContectProviderViewFileList";
import ContextViewFileList from "./Contexts/ContextViewFileList";
import { Button } from "@mui/material";
import { FileListDetails } from "./FileListDetails";

export const ViewFileList = () => {
  

  return (
    <>
    <ContextProviderViewFileList key={"user_specific"} user_specific={true}>
      <FileListDetails />
    </ContextProviderViewFileList>
    <ContextProviderViewFileList key={"not_user_specific"} user_specific={false}>
      <FileListDetails />
    </ContextProviderViewFileList>
    </>
  );
};
