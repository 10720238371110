import React, { useEffect } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, TextField } from "@mui/material";
import {
  CloudDownload,
  CloudUpload,
  CreateNewFolder,
  Download,
} from "@mui/icons-material";
import ContextElementFunctions from "../../Contexts/ContextElementFunctions";
import { downloadFile, downloadFolder } from "./background_tasks";
import { DocumentsService } from "../../../services/openapi";
import APIWrapperContext from "../../../services/APIWrapperContext";
import { Idir } from "../../../Application/MainPages/Interfaces/interfaces";

export const AddFolder = () => {
  const {call_backend_api} = React.useContext(APIWrapperContext);
  const { dir_id, type, name } = React.useContext(ContextElementFunctions);
  const [openNewFolderDialog, setOpenNewFolderDialog] = React.useState(false);
  const [newFolderName, setNewFolderName] = React.useState<string>("");
  const createFolder = () => {
    setOpenNewFolderDialog(true);
  };

  const handleCloseNewFolder = (newFolderName?:string)=>{
      setOpenNewFolderDialog(false);
      if(newFolderName && dir_id){
        call_backend_api(
          DocumentsService.createFolderDocCreateFolderPost(newFolderName,dir_id),
          (res: Idir) => {
            // handleUpdateDocumentList();
          },
          undefined,
          () => {
            
          },
          true,
          "Ordner erstellt!",
          "Ordner konnte nicht erstellt werden!"
        );
      }
    }
  const closeNewFolderDialog = (newFolderName?:string) => {
    if(newFolderName){
      handleCloseNewFolder(newFolderName);
    }
    setOpenNewFolderDialog(false);
  }


  return (
    <>
    { (type === "dir") &&
      <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          createFolder();
        }}
        title={"Ordner erstellen"}
      >
        <CreateNewFolder />
      </IconButton>
      <Dialog open={openNewFolderDialog} onClose={()=>closeNewFolderDialog()} onClick={(e)=>{e.stopPropagation()}}>
         <DialogTitle>Neuer Ordner in {name}</DialogTitle>
         <DialogContent>
           {/* <DialogContentText>
             To subscribe to this website, please enter your email address here. We
             will send updates occasionally.
           </DialogContentText> */}
           <TextField
             autoFocus
             margin="dense"
             id="name"
             label="Neuer Ordnername"
             type="text"
             fullWidth
             onChange={(e) => {setNewFolderName(e.target.value)}}
             variant="standard"
           />
         </DialogContent>
         <DialogActions>
           <Button onClick={()=>closeNewFolderDialog()}>Abbrechen</Button>
           <Button onClick={()=>closeNewFolderDialog(newFolderName)}>OK</Button>
         </DialogActions>
       </Dialog>
      </>}
    </>
  );
};
