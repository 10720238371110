import React from 'react';
import { DirView } from '../../services/openapi/models/DirView';
import { AccessibleDocument } from '../../services/openapi';



interface ContextElementFunctionsInterface {
    dir_id: string;
    type: string;
    name: string;
    download_file: () => void;
    download_folder: () => void;
    
    changeSharedUser: () => void;
    changeSharedUsergroup: () => void;
}


const defaultContext: ContextElementFunctionsInterface = {
    dir_id:"",
    type:"",
    name:"",
    download_file: ()=>{},
    download_folder: ()=>{},

    changeSharedUser: ()=>{},
    changeSharedUsergroup: ()=>{},

    
    
};

const ContextElementFunctions = React.createContext<ContextElementFunctionsInterface>(defaultContext);

export default ContextElementFunctions;
