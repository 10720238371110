import React, { useEffect } from "react";

import { Button, Icon, IconButton } from "@mui/material";
import { CloudDownload, Download, GroupAdd } from "@mui/icons-material";
import ContextElementFunctions from "../../Contexts/ContextElementFunctions";
import { DialogChooseChangeUsersOrUsergroups } from "./ChangeUsersOrUsergroups/DialogChooseChangeUsersOrUsergroups";

export const EditSharedUsersAndUsergroups = () => {
  const { dir_id, type, name } = React.useContext(ContextElementFunctions);
  const [open, setOpen] = React.useState(false);

  const addUserOrUsergroup = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          addUserOrUsergroup();
        }}
        title={"Freigabe ändern"}
      >
        <GroupAdd />
      </IconButton>
      <DialogChooseChangeUsersOrUsergroups
        key={"EditSharedUserOrUsergroups_"+dir_id}
        dir_id={dir_id}
        open={open}
        onClose={(value: any) => {
          setOpen(false);
        }}
      ></DialogChooseChangeUsersOrUsergroups>
    </>
  );
};
