import * as React from "react";

import ContextViewFileList from "../../../Contexts/ContextViewFileList";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { Idir_Users_Userclasses } from "../../../../List/FileList_Components";
import { TonCloseEditSharedUsersgroups } from "../../../../Application/MainPages/Interfaces/interfaces";
import {
  DirView,
  DocumentsService,
  UserService,
} from "../../../../services/openapi";
import ChipTransferList from "../../../../Application/Widgets/ChipTransferList";

interface IEditSharedUsergroupsProps {
  openDialog_EditSharedUsergroups: boolean;
  handleClose: Function;
  //handleClose_EditSharedUsergroups: TonCloseEditSharedUsersgroups
  dir_id: string;
}

interface Iusergroups {
  additionalInformation?: string;
  classname: string;
  endpointsecs?: string[];
  id: number;
  usergroups?: string[];
  users?: string[];
}

const transferlistSettings = {
  actionName: "Nutzergruppen ändern",
  titleLeft: "Freigegebene Nutzergruppen",
  titleRight: "Nicht freigegebene Nutzergruppen",
};

export default function EditSharedUsergroups({
  dir_id,
  openDialog_EditSharedUsergroups,
  handleClose,
}: IEditSharedUsergroupsProps) {
  const { call_backend_api } = React.useContext(APIWrapperContext);
  const { accessible_elements, curr_dir_view, update_accessible_elements } =
    React.useContext(ContextViewFileList);
  const [selectedDocument, setSelectedDocument] = React.useState<
    undefined | DirView
  >();
  const [usergroups, setUsergroups] = React.useState<Iusergroups[]>([]);

  React.useEffect(() => {
    if (openDialog_EditSharedUsergroups) {
      call_backend_api(
        UserService.listUserGroupsUserGroupsGet(),
        (t: Iusergroups[]) => {
          
          setUsergroups(t);
        },
        undefined,
        undefined,
        false,
        undefined,
        undefined,
        true
      );
    }
  }, [openDialog_EditSharedUsergroups,call_backend_api]);

  const handleSaveChanges = (value: string[]) => {
    call_backend_api(
      DocumentsService.updateSharedUsergroupsDocumentByIdDocUpdateSharedUsergroupsPost(
        dir_id,
        value
      ),
      () => {
        update_accessible_elements();
      },
      undefined,
      undefined,
      true,
      "Freigegebene Nutzergruppen aktualisiert",
      "Fehler beim Aktualisieren der freigegebenen Nutzergruppen"
    );
  };

  const handleClose_EditSharedUsergroups = (
    newValue: string[] | undefined,
    action: string | undefined
  ) => {
    if (newValue && action && action === transferlistSettings.actionName) {
      
      handleSaveChanges(newValue);
    }
    handleClose();
  };

  React.useEffect(() => {
    if ("children" in curr_dir_view && curr_dir_view.children !== undefined) {
      setSelectedDocument(
        curr_dir_view.children?.find((el) => el.hidrive_id === dir_id)
      );
    }
  }, [curr_dir_view, dir_id]);

  return (
    <>
      <ChipTransferList
        title={
          "Berechtigte Nutzergruppen für " +
          (selectedDocument ? selectedDocument.parent : "")
        }
        groupList={
          usergroups &&
          selectedDocument &&
          accessible_elements &&
          accessible_elements[dir_id] &&
          accessible_elements[dir_id].usergroups !== undefined
            ? usergroups
                .filter(
                  (u) =>
                    !(
                      accessible_elements[dir_id].usergroups as string[]
                    ).includes(u.classname)
                )
                .map((u) => u.classname)
            : []
        }
        usersGroups={
          selectedDocument &&
          accessible_elements &&
          accessible_elements[dir_id] &&
          accessible_elements[dir_id].usergroups
            ? (accessible_elements[dir_id].usergroups as string[])
            : []
        }
        titleLeft={transferlistSettings.titleLeft}
        titleRight={transferlistSettings.titleRight}
        open={openDialog_EditSharedUsergroups}
        closeFunction={handleClose_EditSharedUsergroups}
        saveActionName={"Nutzergruppen ändern"}
      ></ChipTransferList>
    </>
  );
}
