import React from 'react';
import { call_backend_api } from './APIWrapper';
import { CancelablePromise } from './openapi/core/CancelablePromise';
import { BooleanLiteral } from 'typescript';



interface APIWrapperContextInterface {
    call_backend_api: (promise:CancelablePromise<any>,successHandler:Function|undefined,errorHandler:Function|undefined,finallyHandler:Function|undefined,showSnackbars:boolean,successMessage?:string,errorMessage?:string,showSnackbarsOnError?:boolean)=>void,

}


const defaultContext: APIWrapperContextInterface = {
    
    call_backend_api:call_backend_api,
    
    
    
};

const APIWrapperContext = React.createContext<APIWrapperContextInterface>(defaultContext);

export default APIWrapperContext;
