import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Idir } from "./FileList_Components";
import CloseIcon from "@mui/icons-material/Close";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    border: "1px solid black",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const SearchCancelIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  // position: 'absolute',
  //pointerEvents: 'none',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

interface ISearchBarProps {
  
  documentList?: Idir;
  setDocumentList?: React.Dispatch<React.SetStateAction<Idir|undefined>>;
}

export default function SearchBar({ documentList,setDocumentList }: ISearchBarProps) {
  const [search, setSearch] = React.useState("");

  const handleSearch = (value: string) => {
    value = value.replaceAll("/",">_>");
    let files_containing_search: Idir[] = [];

    const addNodeToListIfContainsSearch = (
      children: Idir[],
      pathname: string
    ) => {
      children.forEach((c) => {
        if (c.parent.includes(value)) {
          c.directLink = pathname;
          files_containing_search.push(c);
        }
        addNodeToListIfContainsSearch(c.children, pathname + " > " + c.parent);
      });
    };
    if (documentList) {
      let clone_documentList: Idir = JSON.parse(JSON.stringify(documentList));
      if (!(value === undefined || value === "")) {
        addNodeToListIfContainsSearch(clone_documentList.children, "");
       
        clone_documentList.children = files_containing_search;
      }
      if(setDocumentList){
          setDocumentList(clone_documentList);

      }
    }
   
  };

  React.useEffect(() => {
    const getData = setTimeout(() => {
      handleSearch(search);
    }, 2000);
    return () => clearTimeout(getData);
  }, [search]);


  

  

  
  

  return (
    <>
      <Search sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          value={search ? search : ""}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          //onInput={(e) => handleSearch(e.target.value)}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ flex: 1 }}
        />
        {search !== undefined && search !== "" && (
          <SearchCancelIconWrapper
            onClick={() => {
              setSearch("");
            }}
          >
            <CloseIcon></CloseIcon>
          </SearchCancelIconWrapper>
        )}
      </Search>
    </>
  );
}
