import React, { useEffect } from "react";
import ContextProviderViewFileList from "./Contexts/ContectProviderViewFileList";
import ContextViewFileList from "./Contexts/ContextViewFileList";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBack, Folder, Home, Image } from "@mui/icons-material";
import { restore_slashes, stringAvatar } from "./background_functions";
import { ViewFileListPath } from "./ViewFileListPath";
import ContextProviderElementFunctions from "./Contexts/ContectProviderElementFunctions";
import { DownloadFileFunction } from "./ElementFunctions/DownloadFunctions/DownloadFileFunction";
import { EditSharedUsersAndUsergroups } from "./ElementFunctions/EditSharedUsersAndUsergroups/EditSharedUsersAndUsergroups";
import { UploadFile } from "./ElementFunctions/UploadFile/UploadFile";
import { AddFolder } from "./ElementFunctions/AddFolder/AddFolder";
import { ShowPDF } from "./ElementFunctions/ShowPDF/ShowPDF";

export const FileListDetails = () => {
  const {
    dir_id_history,
    curr_dir_id,
    curr_dir_view,
    accessible_elements,
    user_specific,
    loading_dir,
    setCurrDirId,
    setCurrDirView,
    init_dir_view,
    dir_navigate_back,
    dir_navigate_forward,
  } = React.useContext(ContextViewFileList);

  

  const handle_dir_click = (id: string) => {
    dir_navigate_forward(id);
  };

  return (
    <div>
      {
        user_specific&&
        <h3 style={{color:'gray'}}>Meine Dokumente</h3>
      }

      <ViewFileListPath />
      { dir_id_history.length>1&&
        <Box sx={{ display: "flex", backgroundColor: "#f0f0f0" }}>
        {
          
          <IconButton onClick={() => dir_navigate_back()} title={"Zurück"} disabled={dir_id_history.length<2}>
          <ArrowBack />
        </IconButton>
        }
        {/* <Button variant="outlined" onClick={() => dir_navigate_back()}>
          <ArrowBack />
          Zurück
        </Button> */}
        {curr_dir_view && "parent" in curr_dir_view && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1 }} />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {restore_slashes(curr_dir_view.parent)}
            </Typography>
            <Box sx={{ flex: 1 }} />
          </Box>
        )}
        <Box sx={{ flex: 1 }} />
        {curr_dir_id !== undefined &&
          curr_dir_view &&
          "parent" in curr_dir_view && user_specific===false &&(
            <Box sx={{}}>
              <ContextProviderElementFunctions
                key={"ContextProviderElementFunctions_" + curr_dir_id}
                curr_dir_id={curr_dir_id}
                type={"dir"}
                name={restore_slashes(curr_dir_view.parent)}
              >
                <UploadFile />
                <AddFolder />
              </ContextProviderElementFunctions>
            </Box>
          )}
      </Box>
}

      {/* <p>{JSON.stringify(curr_dir_view)}</p> */}
      {!("children" in curr_dir_view && curr_dir_view.children?.length
        ? curr_dir_view.children.length
        : 0 > 0) && <> {
          loading_dir?
          <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Skeleton variant="rectangular" sx={{flex:1}} height={50} />
          <Skeleton variant="rectangular" sx={{flex:1}} height={50} />
          </Box>
          
          :<p>Keine Dateien vorhanden</p>}
        
        
        </>
        }
      {"children" in curr_dir_view &&
        (curr_dir_view.children?.length ? curr_dir_view.children.length : 0) >
          0 && (
          <List sx={{}}>
            {"children" in curr_dir_view && curr_dir_view.children &&
              [...curr_dir_view.children]?.sort((a,b)=>a.type===b.type?(a.parent>=b.parent?1:-1):(a.type==='file'?1:-1)).map((el) => (
                <ListItem
                  sx={{
                    ":hover": { backgroundColor: "#f0f0f0", cursor: "pointer" },
                  }}
                  onClick={() => {
                    if (el.type === "dir") {
                      handle_dir_click(el.hidrive_id);
                    } else {
                      // handle Download
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      {el.type === "file" ? (
                        <InsertDriveFileIcon color="primary"></InsertDriveFileIcon>
                      ) : (
                        <Folder />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={restore_slashes(el.parent)} secondary="" />
                  <ListItemAvatar>
                    <Stack direction="row" spacing={1}>
                      {el.hidrive_id in accessible_elements &&
                        "users" in accessible_elements[el.hidrive_id] &&
                        (
                          accessible_elements[el.hidrive_id]["users"] as any
                        ).map((user: string) => (
                          <Avatar {...stringAvatar(user)} title={user} />
                        ))}
                      {el.hidrive_id in accessible_elements &&
                        "usergroups" in accessible_elements[el.hidrive_id] &&
                        (
                          accessible_elements[el.hidrive_id][
                            "usergroups"
                          ] as any
                        ).map((group: string) => (
                          <Avatar {...stringAvatar(group)} title={group} />
                        ))}
                    </Stack>
                  </ListItemAvatar>
                  <ContextProviderElementFunctions
                    key={"ContextProviderElementFunctions_" + el.hidrive_id}
                    curr_dir_id={el.hidrive_id}
                    type={el.type}
                    name={el.parent}
                  >
                    
                    <ShowPDF />
                    <DownloadFileFunction />
                    {(user_specific===undefined || user_specific===false) &&
                    <>
                    <EditSharedUsersAndUsergroups />
                    <UploadFile />
                    <AddFolder />
                    </>
                    }
                  </ContextProviderElementFunctions>
                </ListItem>
              ))}
          </List>
        )}
    </div>
  );
};
