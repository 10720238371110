/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessibleDocument } from '../models/AccessibleDocument';
import type { DirView } from '../models/DirView';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentsService {

    /**
     * Getpartoftree
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getPartOfTreeDocumentsV2GetPartOfTreePost(
requestBody: Array<string>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documents_v2/getPartOfTree',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Trigger Update
     * @returns any Successful Response
     * @throws ApiError
     */
    public static triggerUpdateDocumentsV2TriggerUpdatePost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documents_v2/trigger_update',
        });
    }

    /**
     * Download File which is accessible for User
     * @param id 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reverseProxyDocGetFileGet(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/getFile/',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download File which is accessible for User
     * @param id 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reverseProxyDocFilesDownloadGet(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/Files/download',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a list of all available Documents - this should be Admins only
     * @param id 
     * @param hausverwaltung 
     * @returns DirView Successful Response
     * @throws ApiError
     */
    public static hidriveGetFilesDocGetDirContentGet(
id?: string,
hausverwaltung: boolean = false,
): CancelablePromise<DirView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/getDirContent',
            query: {
                'id': id,
                'hausverwaltung': hausverwaltung,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a list of all available Documents - User specific
     * @param id 
     * @returns DirView Successful Response
     * @throws ApiError
     */
    public static hidriveGetFilesUserDocGetDirContentUserGet(
id?: string,
): CancelablePromise<DirView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/getDirContent_User',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a list of all Users and Usergroups that have access
     * @returns AccessibleDocument Successful Response
     * @throws ApiError
     */
    public static getAccessibleDocsDocGetAccessibleDocsGet(): CancelablePromise<Record<string, AccessibleDocument>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/getAccessibleDocs',
        });
    }

    /**
     * Get a list of all available Documents - this should be Admins only
     * @param hausverwaltung 
     * @param bauarbeiterExtern 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static hidriveGetFilesDocGetFilesGet(
hausverwaltung: boolean = false,
bauarbeiterExtern: boolean = false,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/getFiles',
            query: {
                'hausverwaltung': hausverwaltung,
                'bauarbeiterExtern': bauarbeiterExtern,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a list of all available Documents for User - User specific
     * @returns any Successful Response
     * @throws ApiError
     */
    public static hidriveGetFilesUserDocGetFilesUserGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/getFiles/User',
        });
    }

    /**
     * Update User, die auf Dokument/Ordner zugreifen dürfen
     * @param hidriveId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateDocumentByIdDocUpdatePost(
hidriveId: string,
requestBody?: Array<string>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/doc/update',
            query: {
                'hidrive_id': hidriveId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Usergroups, die auf Dokument/Ordner zugreifen dürfen
     * @param hidriveId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateSharedUsergroupsDocumentByIdDocUpdateSharedUsergroupsPost(
hidriveId: string,
requestBody?: Array<string>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/doc/update/sharedUsergroups',
            query: {
                'hidrive_id': hidriveId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete all Documents - this should be Admins only and never done
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteDocumentsAllDocDeleteAllGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/deleteAll',
        });
    }

    /**
     * Get all Documents - this should be Admins only
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getDocumentsAllDocGetAllGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/doc/getAll',
        });
    }

    /**
     * Create a new Folder - Hausverwaltung Admins
     * @param name 
     * @param parentId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createFolderDocCreateFolderPost(
name: string,
parentId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/doc/createFolder',
            query: {
                'name': name,
                'parentID': parentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete a File - Hausverwaltung Admins
     * @param fileid 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteFileDocDeleteFileHausverwaltungPost(
fileid: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/doc/deleteFileHausverwaltung',
            query: {
                'fileid': fileid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete a Folder - Special Admins only
     * @param dirid 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteFolderDocDeleteFolderDelete(
dirid: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/doc/deleteFolder',
            query: {
                'dirid': dirid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
