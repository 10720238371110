import React, { useEffect } from "react";
import ContextProviderViewFileList from "./Contexts/ContectProviderViewFileList";
import ContextViewFileList from "./Contexts/ContextViewFileList";
import { Button, IconButton } from "@mui/material";
import { FileListDetails } from "./FileListDetails";
import { Home } from "@mui/icons-material";
import { restore_slashes } from "./background_functions";

export const ViewFileListPath = () => {
    const {
        dir_id_history,
      
        dir_navigate_back,
        
      } = React.useContext(ContextViewFileList);

  return (
    <p style={{ backgroundColor: "lightgrey" }}>
        {dir_id_history.map((el, idx) => (
          <>
            {idx == 0 && (
              <IconButton onClick={() => dir_navigate_back(el.id)}>
                <Home />
              </IconButton>
            )}
            {idx > 0 && (
              <Button onClick={() => dir_navigate_back(el.id)}>
                {restore_slashes(el.name)}
              </Button>
            )}
            /
          </>
        ))}
      </p>
  );
};
