import React, { ReactNode, useContext, useEffect } from 'react';


import ContextViewFileList, { I_dir_id_history } from './ContextViewFileList';
import { useNavigate } from 'react-router-dom';

import { call_backend_api } from '../../services/APIWrapper';
import { AccessibleDocument, ApiError, DirView, DocumentsService, UserService, newUser_return } from '../../services/openapi';
import APIWrapperContext from '../../services/APIWrapperContext';
import ContextElementFunctions from './ContextElementFunctions';

interface IContextElementFunctionsProps {
    children?: ReactNode;
    curr_dir_id: string;
    type: string;
    name  : string;

}

const ContextProviderElementFunctions = ({ children,curr_dir_id,type,name }: IContextElementFunctionsProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
   
    const {call_backend_api} = useContext(APIWrapperContext)
   
    const [dir_id,setDirId] = React.useState<string>(curr_dir_id);
    

    const download_file = () => {
        
    }
    const download_folder = () => {
        
    }

    const changeSharedUser = () => {
        
    }
    const changeSharedUsergroup = () => {
        
    }

    useEffect(() => {
        
    }, [dir_id]);
   


    

    return (
        
        <ContextElementFunctions.Provider
            value= {{
                dir_id,
                type,
                name,
                download_file,
                download_folder,

                changeSharedUser,
                changeSharedUsergroup
                

    }
}
        >
    { children }
    </ContextElementFunctions.Provider>
    );
};

export default ContextProviderElementFunctions;
