import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, Grid, ListItemIcon, MenuItem, Paper, TextField, Typography } from '@mui/material';
import PickingList from './PickingList';
import { IDialogRawEditSharedUsersProps, IuserSelectable } from '../../Interfaces/interfaces';
// import PickingList from './PickingList';





export default function DialogRawEditSharedUsers(props:IDialogRawEditSharedUsersProps) {
  const { onClose,  open, title, text, confirmText, users,owners,...other } = props;
  const [value, setValue] = React.useState<undefined|IuserSelectable[]>(undefined);

  
  const [ownersList, setOwnersList] = React.useState(owners);

  const radioGroupRef = React.useRef<HTMLElement>(null);
  
  React.useEffect(() => {
    if (!open) {
      setValue(undefined);
    }
  }, [ open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    
    
    onClose(value);
  };

  
  

  React.useEffect(()=>{
    
    if(ownersList){
     
      setValue(ownersList);
    }
    else{
      
      setValue(undefined)
    }
  },[ownersList])

  
  React.useEffect(()=>{
    
    owners.forEach((el)=>el.checked=false)
  },[owners])


  

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%',  height:'100%',maxHeight:'50%' } }}
      onClick={(e)=>{e.stopPropagation()}}
      maxWidth="md"
      // keepMounted
      // TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <>
        
        </>
          {/* <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText> */}
          
          
      {/* <Typography>Eigentümer</Typography> */}
        <PickingList
          owners={true}
          users={users}
          selectedUsers={owners}
          setListFct={setOwnersList}
        ></PickingList>
      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Nein</Button>
          <Button onClick={handleOk} autoFocus>
            {confirmText}
          </Button>
        </DialogActions>
      
    </Dialog>
  );
}

