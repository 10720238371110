import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Update from "@mui/icons-material/Update";
import EditIcon from "@mui/icons-material/Edit";
import RecursiveFileListe, { Idir, Idir_Users_Userclasses } from "./FileList_Components";
import CloseIcon from "@mui/icons-material/Close";
import { call_backend_api } from "../services/APIWrapper";
import { DocumentsService, OpenAPI } from "../services/openapi";
import APIWrapperContext from "../services/APIWrapperContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ChipTransferList from "../Application/Widgets/ChipTransferList";
import { blue } from "@mui/material/colors";
import { Person } from "@mui/icons-material";
import DialogRawEditSharedUsers from "../Application/MainPages/SubPages/Dokumentenverwaltung/DialogWindowEditSharedUsers";
import FileUploader from "../Application/MainPages/SubPages/Dokumentenverwaltung/FileUploader";
import SearchBar from "./Search";
import { downloadFile } from "./fileListFunction";
import UpdateButton from "./UpdateButton";
import LoadingElement from "./LoadingElement";
import FileActionsMenu from "./FileActionsMenu";
import { fileActions } from "./FileActions/FileActionsDokumentenverwaltung";
import { fileActionsHausverwaltung } from "./FileActions/FileActionsHausverwaltung";
import { fileActionsDownload } from "./FileActions/FileActionsDownload";
import { DialogShowPDF } from "./FileActions/DialogShowPDF";
import { DialogDeleteFile } from "./FileActions/DialogDeleteFile";

import { fileActionsDeleteFolder } from "./FileActions/FileActionsDeleteFolder";

interface IFileListeProps {
  verwaltung?: "hausverwaltung"|"dokumentenverwaltung"|"deleteFolder";
  verwaltung_functions?:Function
}

export default function FileListe({ verwaltung, verwaltung_functions=()=>{}}: IFileListeProps) {
  const { call_backend_api } = React.useContext(APIWrapperContext);
  
  const [openFileUploader, setOpenFileUploader] = React.useState<boolean>(false);
  const [openNewFolderDialog, setOpenNewFolderDialog] = React.useState<boolean>(false);
  //const [selectedDocument, setSelectedDocument] = React.useState(undefined);
  // const startFileUpload = (dir) => {

  // }

  const [openDialog_EditShared, setOpenDialog_EditShared] =
    React.useState<boolean>(false);

  const clickOnDocumentToEditShared = (dir: Idir_Users_Userclasses) => {
    setSelectedDocument(dir);
    setOpenDialog_EditShared(true);
  }

  const [documentList, setDocumentList] = React.useState<Idir | undefined>(
    undefined
  );
  const [tempDocumentList, setTempDocumentList] = React.useState<
    Idir_Users_Userclasses | undefined
  >(undefined);
  const [open, setOpen] = React.useState<Record<string, boolean>>({});
  const [downloading, setDownloading] = React.useState<Record<string, boolean>>(
    {}
  );
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  const [selectedDocument, setSelectedDocument] = React.useState<Idir_Users_Userclasses|undefined>(undefined);

  const [clickedID, setClickedID] = React.useState<string | undefined>(
    undefined
  );

  const [openShowPDF, setOpenShowPDF] = React.useState<boolean>(false);
  const [urlShowPDF, setUrlShowPDF] = React.useState<string>("");
  
  const [openDeleteFile, setOpenDeleteFile] = React.useState<boolean>(false);
  
  const getDocumentsFromServer = () => {
    setShowLoading(true);
    if (verwaltung) {
      call_backend_api(
        DocumentsService.hidriveGetFilesDocGetFilesGet(verwaltung==="hausverwaltung", false),
        (res: Idir) => {
          setDocumentList(res);
        },
        undefined,
        () => {
          setShowLoading(false);
        },
        false
      );
    } else {
      call_backend_api(
        DocumentsService.hidriveGetFilesUserDocGetFilesUserGet(),
        (t: Idir) => setDocumentList(t),
        undefined,
        () => {
          setShowLoading(false);
        },
        true,
        "Dokumente geladen!",
        "Dokumente konnten nicht geladen werden!"
      );
    }
  };
  const handleCloseNewFolder = (newFolderName?:string)=>{
    setOpenNewFolderDialog(false);
    if(newFolderName && selectedDocument){
      call_backend_api(
        DocumentsService.createFolderDocCreateFolderPost(newFolderName,selectedDocument.hidrive_id),
        (res: Idir) => {
          handleUpdateDocumentList();
        },
        undefined,
        () => {
          
        },
        true,
        "Ordner erstellt!",
        "Ordner konnte nicht erstellt werden!"
      );
    }
  }

  const handleUpdateDocumentList = () => {
    getDocumentsFromServer();
  };
  const handleUploadDialogStart = (dir:Idir_Users_Userclasses) => {
    setSelectedDocument(dir);
    setOpenFileUploader(true);
  }
  const handleDeleteFileDialogStart = (dir:Idir_Users_Userclasses) => {
    setSelectedDocument(dir);
    setOpenDeleteFile(true);
  }
  const handleDeleteFileDialogClose = (deleteBool:Boolean=false) => {
    if (deleteBool && selectedDocument && selectedDocument.hidrive_id && verwaltung==="hausverwaltung"){
      call_backend_api(
        DocumentsService.deleteFileDocDeleteFileHausverwaltungPost(selectedDocument.hidrive_id),
        (res: Idir) => {
          handleUpdateDocumentList();
        },
        undefined,
        () => {
          
        },
        true,
        "Datei gelöscht!",
        "Datei konnte nicht gelöscht werden!"
      );
    }
    setOpenDeleteFile(false);
  }
  const handleNewFolderStart = (dir:Idir_Users_Userclasses) => {
    setSelectedDocument(dir);
    setOpenNewFolderDialog(true);
  }

  React.useEffect(() => {
    getDocumentsFromServer();
  }, []);
  React.useEffect(() => {
    if(documentList?.children?.length===1 && documentList.children[0].parent==="Hausverwaltung"){
      documentList.children[0].isPathHausverwaltung = true;
      setTempDocumentList(documentList.children[0])
    }
    else{
    
    setTempDocumentList(documentList);
    }
  }, [documentList]);

  React.useEffect(() => {
   
  }, [downloading]);

  const recursive_GetAllParents = (childs: Idir[], nodeID: string) => {
    let parentsArr: string[] = [];

    if (childs.length === 0) {
      return parentsArr;
    } else {
      // if (childs.reduce((p, c) => c.hidrive_id == nodeID ? true : p, false)) {
      //     return [nodeID]
      // }

      childs.forEach((element) => {
        if (element.hidrive_id == nodeID) {
          parentsArr = parentsArr.concat([element.hidrive_id]);
        }
        let tmp_returnedArr: string[] = [];
        tmp_returnedArr = tmp_returnedArr.concat(
          recursive_GetAllParents(element.children, nodeID)
        );
        if (tmp_returnedArr.length > 0) {
          parentsArr = parentsArr.concat([element.hidrive_id]);
        }
        parentsArr = parentsArr.concat(tmp_returnedArr);
      });

      return parentsArr;
    }
  };

  let testEl = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    
    if (testEl && testEl.current) {
      timeout(400).then((t) => {
        
        if (testEl != null && testEl.current != null)
          testEl.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  });
  async function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const handleClick = (dirName: string, e: React.MouseEvent<HTMLElement>) => {
    
    let temp_open = { ...open };
    temp_open[dirName] = !temp_open[dirName];
    let hidriveID = dirName.split("_");
    let hidriveID_id = hidriveID[hidriveID.length - 1];
    if (temp_open[dirName] && tempDocumentList && tempDocumentList.children) {
      let parentArray = recursive_GetAllParents(
        tempDocumentList.children,
        hidriveID_id
      );
      
      Object.keys(temp_open).forEach((el) => {
        let tmpElement = parentArray.find((op_el) => el.includes(op_el));
        if (tmpElement) {
        } else {
          temp_open[el] = false;
        }
      });
    }

    setClickedID(dirName);
    setOpen(temp_open);
  };

  const callback_setURLShowPDF=React.useCallback((dir:Idir) => {setUrlShowPDF(dir.hidrive_id)},[]);
  const callback_setOpenShowPDF = React.useCallback(() => {setOpenShowPDF(true)},[]);
  const callback_fileActionsDownload = React.useCallback((dir:Idir) => {
    fileActionsDownload(()=>{callback_setURLShowPDF(dir);callback_setOpenShowPDF()})
  },[]);
  

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <SearchBar
          documentList={documentList}
          setDocumentList={setTempDocumentList}
        ></SearchBar>
        <UpdateButton updateFunction={handleUpdateDocumentList}></UpdateButton>
      </Box>
      {/* <LoadingElement showLoading={showLoading}></LoadingElement> */}
      {showLoading &&(
        <>
        <Skeleton variant="rounded" animation="wave" height={40} sx={{ml:3,mr:2,mt:2,  flex:1}}/>
        <Skeleton variant="rounded" animation="wave"  height={40} sx={{ml:3,mr:2,mt:2, flex:1}}/>
        </>
      )}
      {!showLoading && (
        <>
          <List
            sx={{
              // ml: 3 * 0,
              ml:2,mr:2,
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
            }}
            //component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Meine Dokumente
              </ListSubheader>
            }
          >
            {tempDocumentList && tempDocumentList.children && (
              <RecursiveFileListe
                key={"RecursiveFileList"}
                open={open}
                clickedID={clickedID}
                testEl={testEl}
                handleClick={handleClick}
                downloadFile={()=>{}}
                downloading={downloading}
                tempDocumentList={tempDocumentList}
                fileActions={
                  verwaltung==="dokumentenverwaltung"? 
                  // fileActions( clickOnDocumentToEditShared):(verwaltung==="hausverwaltung"?fileActionsHausverwaltung(clickOnDocumentToEditShared,handleUploadDialogStart):fileActionsDownload((dir)=>{callback_fileActionsDownload(dir)}))
                  fileActions( clickOnDocumentToEditShared):(verwaltung==="hausverwaltung"?fileActionsHausverwaltung(clickOnDocumentToEditShared,handleUploadDialogStart,handleNewFolderStart,handleDeleteFileDialogStart):((verwaltung =="deleteFolder")?fileActionsDeleteFolder(verwaltung_functions):fileActionsDownload((dir)=>{setUrlShowPDF(dir.hidrive_id);setOpenShowPDF(true)})))
                }
                isPathHausverwaltung={tempDocumentList.isPathHausverwaltung}
              ></RecursiveFileListe>
            )}
            {
              // search !== undefined &&
              //   search !== "" &&
              tempDocumentList && tempDocumentList.children.length === 0 && (
                <p style={{ margin: "auto", textAlign: "center" }}>
                  Leider wurden keine Dokumente gefunden.
                </p>
              )
            }
          </List>
        </>
      )}
      <FileActionsMenu
        selectedDocument={selectedDocument}
        openDialog_EditShared={openDialog_EditShared}
        setOpenDialog_EditShared={setOpenDialog_EditShared}
        handleUpdateDocumentList={handleUpdateDocumentList}
        openFileUploader={openFileUploader}
        closeFileUploaderFunction ={()=>setOpenFileUploader(false)}
        openNewFolderDialog={openNewFolderDialog}
        closeNewFolderDialog={ handleCloseNewFolder}
      ></FileActionsMenu>
      <DialogDeleteFile onClose={handleDeleteFileDialogClose} open={openDeleteFile} filename={selectedDocument?selectedDocument.parent:""}></DialogDeleteFile>
      <DialogShowPDF onClose={()=>{setOpenShowPDF(false)}} open={openShowPDF} url={urlShowPDF}></DialogShowPDF>
    </>
  );
}
