import React, { useEffect } from "react";

import { Button, Icon, IconButton } from "@mui/material";
import { CloudDownload, Download } from "@mui/icons-material";
import ContextElementFunctions from "../../Contexts/ContextElementFunctions";
import { downloadFile, downloadFolder } from "./background_tasks";

export const DownloadFileFunction = () => {
  const { dir_id, type, name } = React.useContext(ContextElementFunctions);

  const download_file = () => {
    downloadFile(dir_id, name);
  };

  const download_folder = () => {
    downloadFolder(dir_id, name);
  };
  const download = () => {
    if (type === "dir") {
      downloadFolder(dir_id, name);
    } else {
      downloadFile(dir_id, name);
    }
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          download();
        }}
        title={
          type === "dir"
            ? "Ordner als .zip herunterladen"
            : "Datei herunterladen"
        }
      >
        <CloudDownload />
      </IconButton>
      {/* <p>{dir_id}</p> */}
    </>
  );
};
