import * as React from "react";

import APIWrapperContext from "../../services/APIWrapperContext";
import { Idir_Users_Userclasses } from "../FileList_Components";
import ChipTransferList from "../../Application/Widgets/ChipTransferList";
import { UserService } from "../../services/openapi/services/UserService";
import { TonCloseEditSharedUsersgroups } from "../../Application/MainPages/Interfaces/interfaces";


interface IEditSharedUsergroupsProps {
    selectedDocument: Idir_Users_Userclasses | undefined;
    openDialog_EditSharedUsergroups:boolean;
    handleClose_EditSharedUsergroups: TonCloseEditSharedUsersgroups
}

interface Iusergroups {
  additionalInformation?:string
classname:string
endpointsecs?:string[]
id:number
usergroups?:string[]
users?:string[]
}

export default function EditSharedUsergroups({selectedDocument,openDialog_EditSharedUsergroups,handleClose_EditSharedUsergroups}: IEditSharedUsergroupsProps) {
  const { call_backend_api } = React.useContext(APIWrapperContext);

  const [usergroups, setUsergroups] = React.useState<Iusergroups[]>([]);

  React.useEffect(() => {
    call_backend_api(
      UserService.listUserGroupsUserGroupsGet(),
      (t: Iusergroups[]) => {setUsergroups(t)},
      undefined,
      undefined,
      false
    );
  }, []);

  
  

  

  
  return (
    <>
      
      <ChipTransferList
        title={
          "Berechtigte Nutzergruppen für " + (selectedDocument
            ? selectedDocument.parent
            : "")
        }
        groupList={
          usergroups && selectedDocument && selectedDocument.sharedUsergroups!==undefined
            ? usergroups.filter(
                (u) => !(selectedDocument.sharedUsergroups as string[]).includes(u.classname)
              ).map(u=>u.classname)
            : []
        }
        usersGroups={
          selectedDocument && selectedDocument.sharedUsergroups
            ? selectedDocument.sharedUsergroups
            : []
        }
        titleLeft="Freigegebene Nutzergruppen"
        titleRight="Nicht freigegebene Nutzergruppen"
        open={openDialog_EditSharedUsergroups}
        closeFunction={handleClose_EditSharedUsergroups}
        saveActionName={"Nutzergruppen ändern"}
      ></ChipTransferList>
    </>
  );
}
