import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import APIWrapperContext from "../../../services/APIWrapperContext";
import { UserService } from "../../../services/openapi";
import UserContext from "../../../Contexts/UserContext/UserContext";
interface IUserDetailsProps {}
//

export default function UserDetails(props: IUserDetailsProps) {
    const {call_backend_api} = React.useContext(APIWrapperContext)
    const [userDetails, setUserDetails] = React.useState<any>({})
    const [userMetaDetails, setUserMetaDetails] = React.useState<any>({})
    const {name,additionalUserdata, user_roll} = React.useContext(UserContext)
    React.useEffect(() => {
        // call_backend_api(UserService.readUsersMeUserMeGet(),(t:any)=>setUserDetails(t),undefined,undefined,false)
        // call_backend_api(UserService.meAdditionalUserDataUserMeAdditionalUserDataGet(),(t:any)=>setUserMetaDetails(t),undefined,undefined,false)
    },[])

  return (
    <>
    {(user_roll /*&& userDetails.userclass.includes("Eigentümer")*/) && (additionalUserdata)&&(
      <Box
      sx={{
        display: "flex",
        flexDirection: {xs:"column",md:"row"},
        justifyContent: "center",
        alignItems: "left",
        //border: "2px solid gray",
        p: 1,
        mb: 2,
        mr: {xs:0.5,md:1},
        ml: {xs:0.5,md:0},
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography>Eigentümer: {name}</Typography>
        {/* <Typography>Kundennummer: {userMetaDetails.Kundennummer?userMetaDetails.Kundennummer:""}</Typography> */}
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography>Ihr Ansprechpartner: {additionalUserdata.Ansprechpartner?additionalUserdata.Ansprechpartner:""}</Typography>
        <Typography>Schreiben Sie uns eine Email: {additionalUserdata.Mail_Ansprechpartner?additionalUserdata.Mail_Ansprechpartner:""}</Typography>
      </Box>
    </Box>)
    }
    </>
    // </ThemeProvider>
  );
}
