import List from "@mui/material/List";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Close, Person } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { OpenAPI } from "../../../services/openapi";
import { PDFObject } from "react-pdfobject";

interface IDialogShowPDFProps {
  onClose: Function;
  open: boolean;
  url: string;
}

export function DialogShowPDF({ onClose, open, url }: IDialogShowPDFProps) {
  const handleClose = () => {
    setUrlTmp("");
    onClose();
  };

  const [urlTmp, setUrlTmp] = useState<string>("");
  useEffect(() => {
    if (open) {
      let url_link = "" + OpenAPI.BASE + "/doc/getFile/?id=" + url;
      fetch(url_link, {
        method: "GET",

        mode: "cors",
        headers: {
          Accept: "application/json",
        },
      })
        .then((result) => {
          if (result.status != 200) {
            throw new Error("Bad server response");
          }

          return result.blob();
        })

        .then((data) => {
          setUrlTmp(URL.createObjectURL(data));
        });
    }
  }, [url, open]);
  useEffect(() => {}, [open, urlTmp]);

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open={open}
      maxWidth={"xl"}
      style={{
        maxHeight: "80vh",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DialogTitle><Box sx={{display:"flex",flexDirection:'row'}}>
<Box sx={{flex:1}}>Schnellansicht</Box>
        <IconButton onClick={() => handleClose()}>
          <Close></Close>
        </IconButton>
        </Box></DialogTitle>
      <DialogContent sx={{ height: "80vh",display:'flex', flexDirection:'column' }}>
        
        {urlTmp !== "" && (
          <Box sx={{flex:1}}>
            <div
              id="testPDF"
              style={{
                // position: "fixed",
                // top: "0px",
                // left: "0px",
                flex: 1,
                width: "100%",
                height: "100%",
                zIndex: 300000,
                // backgroundColor: "green",
              }}
            >
              <PDFObject containerId={"testPDF"} height="100%" width="100%" url={urlTmp} pdfOpenParams={{view:'FitH'}}/>
            </div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
