import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IndicatorWorktime from "./IndicatorWorktime";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import { ArbeitszeitService, OpenAPI } from "../../../../../services/openapi";
import {
  addDaysToDate,
  convertDateToISODateString,
  getIsoWeekOfDate,
} from "../timehelperFunctions";
import { IWorktime } from "./interfaces";
import dayjs from "dayjs";
import LinkGeneratorForNFC from "./LinkGeneratorForNFC";
import { DatePicker } from "@mui/x-date-pickers";
import ZeiterfassungDashboardContext from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContext";

export default function FilterDepartment() {
  const {arbeitszeiten_alle_MA,setFilter,filter} = useContext(ZeiterfassungDashboardContext)
    const [selectedDepartment,setSelectedDepartment] = useState<string>(arbeitszeiten_alle_MA && arbeitszeiten_alle_MA['departments']&& arbeitszeiten_alle_MA['departments'].length>0?arbeitszeiten_alle_MA['departments'][0]:"")
    const [initDepartment,setInitDepartment] = useState<string>(arbeitszeiten_alle_MA && arbeitszeiten_alle_MA['departments']&& arbeitszeiten_alle_MA['departments'].length>0?arbeitszeiten_alle_MA['departments'][0]:"")
  useEffect(() => {
    setFilter({
        department:selectedDepartment
    })
    
  }, [
   selectedDepartment,setFilter 
  ]);
  useEffect(() => {
    if ((!initDepartment)&&arbeitszeiten_alle_MA && arbeitszeiten_alle_MA['departments']&& arbeitszeiten_alle_MA['departments'].length>0){

        setInitDepartment(arbeitszeiten_alle_MA['departments'][0]);
        setSelectedDepartment(arbeitszeiten_alle_MA['departments'][0])
    }
  }, [
    arbeitszeiten_alle_MA
  ]);
  const handleChange = (event: SelectChangeEvent)=>{
    setSelectedDepartment(event.target.value)

  }

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      
      <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Firma/Abteilung
            </InputLabel>
            <Select
                
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedDepartment==''?'Alle':selectedDepartment}
              
                MenuProps={{
                    autoFocus:false
                }}
              onChange={handleChange}
              label="Firma/Abteilung"
            >
              <MenuItem value=""
                
              >
                <em>
                    
                    Alle
                    </em>
              </MenuItem>
              {arbeitszeiten_alle_MA && ("departments" in arbeitszeiten_alle_MA) && (arbeitszeiten_alle_MA['departments'].map((dep:string) => (
                <MenuItem value={dep}
                selected={dep===selectedDepartment}
                >
                    
                  {dep}
                </MenuItem>
              )))}
            </Select>
          </FormControl>
      
          
      
    </>
    // </ThemeProvider>
  );
}
